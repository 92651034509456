import { createApp } from "vue";
import { VueCookieNext } from "vue-cookie-next";
import App from "./App.vue";
import router from './router'

createApp(App).use(router)
  .use(VueCookieNext)
  .mount("#app");

VueCookieNext.config({ expire: "1d" });
