<template>
  <router-view />
</template>

<style>
/* Hide scrollbar for IE, Edge and Firefox */
body {
  overflow: hidden; /* Hide scrollbars */
  overflow-y: hidden; /* Hide vertical scrollbar */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
/* Hide scrollbar for Chrome, Safari and Opera */
body::-webkit-scrollbar {
  display: none;
}
</style>
